import React from 'react'
import {Link} from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Logo from '../../svg/CS-Logo_white_noGlow.svg'

function Menu (props) {
  const {menus, language, isSubPage} = props
  return (

    <nav className='main-nav'>
      {
        !isSubPage
          ? menus.menuitems.map((item, index) => (
            item.menutype ? <h2 key='Logo' className='logo'>
              <Link to='/'><Logo /></Link>
            </h2>
              : <AnchorLink key={index} href={`#${item.url}`} data-testid={`main-nav-${item.url}`}>{language === 'de' ? item.name.de : item.name.en}</AnchorLink>
          ))
          : menus.menuitems.map((item, index) => (
            item.menutype ? <h2 key='Logo' className='logo'>
              <Link to='/'><Logo /></Link>
            </h2>
              : <Link key={index} to={`/de/#${item.url}`}>{language === 'de' ? item.name.de : item.name.en}</Link>
          ))
      }
    </nav>
  )
}

export default Menu
