import React, {useState} from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import {animateScroll as scroll} from 'react-scroll'

const CallToAction = ({callToAction}) => {
  return (

    <AnimatePresence>
      {callToAction.toggled && (

        <motion.div
          style={{overflow: 'hidden'}}
          initial={{opacity: 0, height: 0}}
          animate={{opacity: 1, height: 'auto'}}
          exit={{opacity: 0, height: 0}}
          transition={{duration: 0.7}}
        >
          <motion.div
            className='callToAction-wrapper'
            initial={{y: -200}}
            animate={{y: 0}}
            exit={{y: -200}}
            transition={{duration: 0.05}}
          >
            <h1>{callToAction.headline}</h1>
            <button className='button submit-button' onClick={callToAction.handleCallToAction}>{callToAction.buttonText}</button>
            <div className='close-button' onClick={callToAction.closeCallToAction}>x</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default CallToAction
