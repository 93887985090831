import React from 'react'
import {motion} from 'framer-motion'
import {Link} from 'gatsby'
import LogoMobile from '../../../svg/CS-Logo_White_mobile.svg'
import {MenuItem} from './MenuItem'
import Language from '../../language/language'

const variants = {
  open: {
    transition: {staggerChildren: 0.07, delayChildren: 0.2, opacity: 1}
  },
  closed: {
    transition: {staggerChildren: 0.05, staggerDirection: -1, opacity: 0}
  }
}

const variantsLogo = {
  open: {
    y: 0,
    x: -50,
    opacity: 1,
    display: 'block',
    transition: {
      y: {stiffness: 1000, velocity: -100}
    }
  },
  closed: {
    y: 50,
    x: -50,
    opacity: 0,
    display: 'none',
    transition: {
      y: {stiffness: 1000}
    }
  }
}

const variantsLanguage = {
  open: {
    y: -100,
    x: 220,
    opacity: 1,
    display: 'block',
    transition: {
      y: {stiffness: 1000, velocity: -100}
    }
  },
  closed: {
    y: -90,
    x: 220,
    opacity: 0,
    display: 'none',
    transition: {
      y: {stiffness: 1000}
    }
  }
}

export const Navigation = (props) => {
  const {menus, language, toggle, isSubPage} = props

  return (
    <motion.ul variants={variants}>
      <motion.div variants={variantsLanguage} className='mobile-menu--language'>
        <Language />
      </motion.div>
      <motion.h2 variants={variantsLogo} className='logo'>
        <Link to='/'><LogoMobile /></Link>

      </motion.h2>

      {menus.menuitems.map(item => {
        return <MenuItem i={item._key} key={item._key} title={item} url={item.url} menutype={item.menutype} language={language} toggle={toggle} isSubPage={isSubPage} />
      })}

    </motion.ul>
  )
}
