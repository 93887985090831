import React from 'react'
import {motion} from 'framer-motion'
import {Link as GatsbyLink} from 'gatsby'
import {Link, Events} from 'react-scroll'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    display: 'block',
    transition: {
      y: {stiffness: 1000, velocity: -100}
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    display: 'none',
    transition: {
      y: {stiffness: 1000}
    }
  }
}

export const MenuItem = ({i, title, url, menutype, language, toggle, isSubPage}) => {
  Events.scrollEvent.register('end', () => { toggle() })
  return (
    menutype ? null
      : <motion.li
        variants={variants}
        whileHover={{scale: 1.1}}
        whileTap={{scale: 0.95}}

      >
        {
          isSubPage
            ? <GatsbyLink to={`/de/#${url}`}>{language === 'de' ? title.name.de : title.name.en}</GatsbyLink>
            : <Link to={url} spy smooth duration={1000}>{language === 'de' ? title.name.de : title.name.en}</Link>
        }

      </motion.li>
  )
}
