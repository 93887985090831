import React from 'react'
import {Link as GatsbyLink} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram, faTwitter, faLinkedin} from '@fortawesome/free-brands-svg-icons'
import {Link} from 'react-scroll'
import '../styles/layout.css'
import styles from './layout.module.css'
import Language from './language/language'

const Footer = ({menus, language, socialMedia, isSubPage}) => {
  return (
    <div>
      <footer className={styles.footer}>
        <div className={styles.footerWrapper}>
          <nav className='footer--info'>
            <span>©{new Date().getFullYear()} Christian Schleicher</span>
            <div className='footer--credits'>
              Made with ❤️ by <a href='https://www.runemoller.com' target='_blank'>RM</a>
            </div>
            <GatsbyLink to='/impressum'>Impressum</GatsbyLink>
            <GatsbyLink to='/datenschutz'>Datenschutz</GatsbyLink>
          </nav>
          {isSubPage
            ? <nav className='footer--nav'>
              <GatsbyLink to='/'>Start</GatsbyLink>
              {menus.menuitems.map((item, index) => (
                item.menutype ? null
                  : <GatsbyLink key={index} to={`/de/#${item.url}`}>{item.name.de}</GatsbyLink>
              ))}
              <Language />
            </nav>

            : <nav className='footer--nav'>
              <Link to='/'>Start</Link>
              {menus.menuitems.map((item, index) => (
                item.menutype ? null
                  : <Link key={index} to={item.url} spy smooth duration={1000}>{item.name.de}</Link>
              ))}
              <Language />
            </nav>
          }
          <ul className='footer--social'>

            {socialMedia.socialMediaItems.map((item, index) => (
              <li key={index}>
                <a href={item.url} target='_blank' rel='noopener noreferrer'>
                  {item.name[0] === 'instagram' ? <FontAwesomeIcon icon={faInstagram} /> : null }
                  {item.name[0] === 'facebook' ? <FontAwesomeIcon icon={faFacebook} /> : null}
                  {item.name[0] === 'twitter' ? <FontAwesomeIcon icon={faTwitter} /> : null}
                  {item.name[0] === 'linkedin' ? <FontAwesomeIcon icon={faLinkedin} /> : null}
                </a>
              </li>
            ))}
          </ul>

        </div>
      </footer>
    </div>
  )
}

export default Footer
