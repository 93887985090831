import React from 'react'
import MobileMenu from './menu/mobileMenu/Mobilemenu'
import Menu from './menu/Menu'
import CallToAction from '../components/callToAction/CallToAction'

const Header = (props) => {
  const {language, menus, isSubPage, site, callToAction} = props

  return (
    <>
      <div className='header'>
        <CallToAction site={site} callToAction={callToAction} />
        <div className='navigation-wrapper'>
          <div className='mobile-menu'><MobileMenu menus={menus} language={language} isSubPage={isSubPage} /></div>
          <Menu menus={menus} language={language} isSubPage={isSubPage} />
        </div>
      </div>
    </>
  )
}

export default Header
