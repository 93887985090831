import React from 'react'
import {IntlContextConsumer, changeLocale} from 'gatsby-plugin-intl'

const languageName = {
  en: 'EN',
  de: 'DE'
}

const Language = () => {
  return (

    <IntlContextConsumer>
      {({languages, language: currentLocale}) =>
        languages.map(language => {
          if (currentLocale == language) { return null }
          return <a key={language} className='lang' onClick={() => changeLocale(language)}>
            {languageName[language]}
          </a>
        }

        )
      }
    </IntlContextConsumer>

  )
}

export default Language
