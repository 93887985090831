import React from 'react'
import Header from './header'
import '../styles/layout.css'
import styles from './layout.module.css'
import Footer from './footer'
import {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat';
    -webkit-font-smoothing: antialiased;
    background: var(--color-white);
    color: var(--color-black);
    margin: 0;
    box-sizing: border-box;
    background: url("https://res.cloudinary.com/rune-moller-web-design/image/upload/v1581879881/christian-schleicher/d4.jpg")
      repeat scroll 0 0 rgba(0, 0, 0, 0);
  }
`

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, language, menus, pageContext, blog, site, socialMedia, callToAction, isSubPage}) => (
  <>
    <GlobalStyle />
    <Header siteTitle={siteTitle} onHideNav={onHideNav} language={language} onShowNav={onShowNav} showNav={showNav} menus={menus} pageContext={pageContext} isSubPage={isSubPage} site={site} callToAction={callToAction} />
    <div className={styles.content}>{children}</div>
    <Footer menus={menus} language={language} socialMedia={socialMedia} isSubPage={isSubPage} />
  </>
)

export default Layout
