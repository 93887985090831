import React from 'react'
import {motion} from 'framer-motion'

const Path = props => (
  <motion.path
    fill='transparent'
    strokeWidth='4'
    stroke='#fff'
    strokeLinecap='round'
    {...props}
  />
)

export const MenuToggle = ({toggle}) => (
  <button onClick={toggle}>
    <div className='lines'>

      <svg width='35' height='20' viewBox='0 0 50 20'>
        <Path
          variants={{
            closed: {d: 'M 3 1.5 L 40 1.5'},
            open: {d: 'M 10 19 L 33 2.5'}
          }}
        />
        <Path
          d='M 3 10.423 L 40 10.423'
          variants={{
            closed: {opacity: 1},
            open: {opacity: 0}
          }}
          transition={{duration: 0.1}}
        />
        <Path
          variants={{
            closed: {d: 'M 3 19.346 L 40 19.346'},
            open: {d: 'M 10 2.5 L 33 19'}
          }}
        />
      </svg>
    </div>
    <div className='mic'>

      <svg width='60px' height='102px' viewBox='0 0 60 102' version='1.1' xmlns='http://www.w3.org/2000/svg'>

        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='Desktop-HD' transform='translate(-697.000000, -543.000000)' fill='#ffffff' fillRule='nonzero'>
            <g id='noun_Microphone_1230400' transform='translate(697.000000, 543.000000)'>
              <path d='M30.8020651,83 C44.1445341,83 55,74.2788294 55,63.5607731 L55,19.4544317 C55,8.72623882 44.1445341,0 30.8020651,0 L30.2029693,0 C16.8567245,0 6,8.72623882 6,19.4544317 L6,63.5607731 C6,74.2788294 16.8567245,83 30.2029693,83 L30.8020651,83 Z M6,63 L6,21 C14.8162437,21 44.8523019,21 55,21 L55,63 C28.0225194,63 11.6891861,63 6,63 Z' id='Shape' />
              <path d='M60,77.0323971 C60,75.9094856 58.9033306,75 57.5512591,75 C56.197238,75 55.1005686,75.9094856 55.1005686,77.0323971 C55.1005686,83.4537697 43.7858652,88.8807713 30.3899269,88.8807713 L29.6100731,88.8807713 C16.2151097,88.8807713 4.89943136,83.4537697 4.89943136,77.0323971 C4.89943136,75.9094856 3.80276198,75 2.44971568,75 C1.09666937,75 0,75.9094856 0,77.0323971 C0,85.5807533 11.9434606,92.3497515 27.5473599,92.9027187 L27.5473599,97.9368226 L19.8716491,97.9368226 C18.5186028,97.9368226 17.4219334,98.8463082 17.4219334,99.9676029 C17.4219334,101.090514 18.5186028,102 19.8716491,102 L40.122502,102 C41.4745735,102 42.5712429,101.090514 42.5712429,99.9676029 C42.5712429,98.8463082 41.4745735,97.9368226 40.122502,97.9368226 L32.4458164,97.9368226 L32.4458164,92.9027187 C48.0545898,92.3513683 60,85.5807533 60,77.0323971 Z' id='Path' />
            </g>
          </g>
        </g>
      </svg>

    </div>
  </button>
)
