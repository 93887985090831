import React, {useRef} from 'react'

import {motion, useCycle} from 'framer-motion'
import {useDimensions} from '../../../utils/use-dimensions'

import {MenuToggle} from './MenuToggle'
import {Navigation} from './Navigation'

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: 'circle(0px at 40px 40px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 500,
      damping: 40
    }
  }
}

const MobileMenu = (props) => {
  const {menus, language, isSubPage} = props
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const {height} = useDimensions(containerRef)

  return (

    <motion.nav
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}
    >
      <motion.div className='background' variants={sidebar} />
      <Navigation menus={menus} language={language} toggle={() => toggleOpen()} isSubPage={isSubPage} />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>

  )
}

export default MobileMenu
